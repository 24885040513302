import React, { useState } from 'react';
import { Typography, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const QueryForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

const SubmitButton = styled(Button)`
  background-color: #feb47b;
  color: #ffffff;
  margin-top: 20px;
  &:hover {
    background-color: #ff7e5f;
  }
`;

function UserQueryForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/query`, formData);
      
      if (response.status === 201) {
        alert('Query submitted successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error submitting query:', error.response ? error.response.data : error.message);
      alert('An error occurred while submitting your query.');
    }
  };
  

  return (
    <FormWrapper>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        User Query Form
      </Typography>
      <QueryForm onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Your Query"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <SubmitButton type="submit">Submit</SubmitButton>
      </QueryForm>
    </FormWrapper>
  );
}

export default UserQueryForm;

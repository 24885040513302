import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';

const NavbarContainer = styled(AppBar)`
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #ffffff !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const NavbarWrapper = styled(Toolbar)`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; /* Center the navbar content */
`;

const NavLinks = styled('div')`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none; /* Hide links on smaller screens */
  }
`;

const NavLinkButton = styled(Button)`
  color: ${(props) => (props.active === 'true' ? '#ffffff' : '#000000')} !important;
  background-color: ${(props) => (props.active === 'true' ? '#333333' : 'transparent')} !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 10px 15px !important;
  border-radius: 4px !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;

  &:hover {
    color: #ffffff !important;
    background-color: #333333 !important;
  }
`;

const Logo = styled(Typography)`
  font-size: 24px !important;
  font-weight: bold !important;
  color: #000000 !important;

  @media (max-width: 768px) {
    text-align: center;
    flex: 1; /* Expand to fill space */
  }
`;

const MobileMenuIcon = styled(IconButton)`
  display: none;
  @media (max-width: 768px) {
    display: block; /* Show menu icon on smaller screens */
    margin-right: 20px;
  }
`;

const DrawerList = styled(List)`
  width: 150px;
`;

const StyledListItem = styled(ListItem)`
  ${(props) => props.active && `
    background-color: #000000 !important;
    & .MuiListItemText-root {
      color: #ffffff !important;
    }
  `}
`;

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <NavbarContainer>
      <NavbarWrapper>
        <Logo variant="h6">Manual Finder</Logo>
        <MobileMenuIcon onClick={toggleDrawer}>
          <MenuIcon />
        </MobileMenuIcon>
        <NavLinks>
          <NavLinkButton
            component={Link}
            to="/home"
            active={(location.pathname === '/home').toString()}
          >
            Home
          </NavLinkButton>
          <NavLinkButton
            component={Link}
            to="/upload"
            active={(location.pathname === '/upload').toString()}
          >
            Upload Document
          </NavLinkButton>
          <NavLinkButton
            component={Link}
            to="/pricing"
            active={(location.pathname === '/pricing').toString()}
          >
            Pricing Card
          </NavLinkButton>
          <NavLinkButton
            component={Link}
            to="/queryPage"
            active={(location.pathname === '/queryPage').toString()}
          >
            User Query
          </NavLinkButton>
          <NavLinkButton onClick={handleSignOut} startIcon={<ExitToAppIcon />} >
            Sign Out
          </NavLinkButton>
        </NavLinks>
      </NavbarWrapper>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: { width: '150px', maxHeight: '30vh', overflow: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.8)' }
        }}
      >
        <DrawerList>
          <StyledListItem button component={Link} to="/home" active={location.pathname === '/home'}>
            <ListItemText primary="Home" />
          </StyledListItem>
          <StyledListItem button component={Link} to="/upload" active={location.pathname === '/upload'}>
            <ListItemText primary="Upload Document" />
          </StyledListItem>
          <StyledListItem button component={Link} to="/pricing" active={location.pathname === '/pricing'}>
            <ListItemText primary="Pricing Card" />
          </StyledListItem>
          <StyledListItem button component={Link} to="/queryPage" active={location.pathname === '/queryPage'}>
            <ListItemText primary="User Query" />
          </StyledListItem>
          <StyledListItem button onClick={handleSignOut}>
            <ListItemText primary="Sign Out" />
          </StyledListItem>
        </DrawerList>
      </Drawer>
    </NavbarContainer>
  );
}

export default Navbar;

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  GlobalStyles,
} from '@mui/material';
import { styled } from '@mui/system';
import Sidebar from '../components/Sidebar'; // Import your Sidebar and Navbar
import Navbar from '../components/AdminNavbar';
import Footer from '../components/Footer';
// Styled components with responsive design
const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column', // Stack vertically on smaller screens
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginTop: '60px', // Adjust based on navbar height
  marginLeft: '90px', // Adjust based on sidebar width
  width: `calc(100% - 180px)`, // Adjust content width
  [theme.breakpoints.down('md')]: {
    marginLeft: 0, // Remove left margin on smaller screens
    width: '100%', // Full width on smaller screens
  },
}));

const ReportContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3) || '24px',
  minHeight: 'calc(100vh - 60px)', 
  background: 'linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eac9)',
  backgroundSize: '800% 800%',
  animation: 'gradientBackground 15s ease infinite',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2) || '16px',
    marginLeft: '90px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2, 0) || '16px 0',
  backgroundColor: '#ffffff',
  boxShadow: theme.shadows ? theme.shadows[3] : '0px 3px 6px rgba(0, 0, 0, 0.1)', 
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#37474f',
  borderBottom: `2px solid ${theme.palette.divider || '#ccc'}`,
}));

const reports = [
  {
    name: 'Alice Johnson',
    email: 'alice@example.com',
    message: 'Unable to login to the portal.',
    status: 'Open',
  },
  {
    name: 'Bob Smith',
    email: 'bob@example.com',
    message: 'Found a bug in the dashboard.',
    status: 'Closed',
  },
  {
    name: 'Charlie Brown',
    email: 'charlie@example.com',
    message: 'Requesting access to admin features.',
    status: 'Pending',
  },
];

const statusColors = {
  Open: 'primary',
  Closed: 'default',
  Pending: 'secondary',
};

const ReportPage = () => {
  return (
    <>
      <GlobalStyles
        styles={{
          '@keyframes gradientBackground': {
            '0%': { backgroundPosition: '0% 50%' },
            '50%': { backgroundPosition: '100% 50%' },
            '100%': { backgroundPosition: '0% 50%' },
          },
        }}
      />
      <PageContainer>
        <Sidebar selected="report" onSelect={() => {}} />
        <ContentContainer>
          <Navbar />
          <ReportContainer>
            <StyledCard>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Message</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((report, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body1">{report.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">{report.email}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">{report.message}</Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={report.status}
                            color={statusColors[report.status]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledCard>
          </ReportContainer>
          <Footer/>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default ReportPage;

import React from 'react';
import { Container, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const FooterWrapper = styled(Box)`
  background-color: #ffffff;
  color: #333;
  padding: 10px 5px;
  text-align: center;
  border-top: 1px solid #e7e7e7;
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterLinks = styled(Box)`
  display: flex;
  gap: 20px;
  font-size: 14px;
`;

const FooterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterText = styled(Typography)`
  margin: 0;
  font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLinks>
          <FooterLink href="/about" underline="none">
            About
          </FooterLink>
          <FooterLink href="/contact" underline="none">
            Contact
          </FooterLink>
          <FooterLink href="/privacy" underline="none">
            Privacy Policy
          </FooterLink>
          <FooterLink href="/terms" underline="none">
            Terms of Service
          </FooterLink>
        </FooterLinks>
        <FooterText>&copy; 2024 Trixly. All rights reserved.</FooterText>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;

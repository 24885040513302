import React, { useState } from 'react';
import { Box, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { Analytics, Upload, Report, Menu } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

// Styled components
const SidebarContainer = styled(Box)(({ theme, isSidebarOpen }) => ({
  position: 'fixed',
  top: '60px',
  left: 0,
  height: '100vh',
  width: isSidebarOpen ? '200px' : '70px',
  backgroundColor: 'white',
  color: 'black',
  padding: theme.spacing(2),
  overflowY: 'auto',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
  transition: 'width 0.3s ease',
  [theme.breakpoints.down('md')]: {
    width: '60px', 
  },
}));

const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  '&:hover': {
    backgroundColor: '#1abc9c',
    color: 'white',
  },
  backgroundColor: isSelected ? '#1abc9c' : 'inherit',
  color: isSelected ? 'white' : 'inherit',
  transition: 'background-color 0.3s, color 0.3s',
  display: 'flex',
  alignItems: 'center',
}));

const TextTransition = styled(ListItemText)(({ theme, isSidebarOpen }) => ({
  transition: 'opacity 0.3s ease',
  opacity: isSidebarOpen ? 1 : 0,
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'none', 
  },
}));

const Sidebar = ({ selected, onSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleNavigation = (path) => {
    if (typeof onSelect === 'function') {
      onSelect(path); 
    }
    navigate(`/${path}`); 
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <SidebarContainer isSidebarOpen={isSidebarOpen}>
      <IconButton
        onClick={handleSidebarToggle}
        sx={{ color: 'black', marginBottom: 2 }}
      >
        <Menu />
      </IconButton>
      <Typography variant="h6" gutterBottom>
        Admin Panel
      </Typography>
      <List>
        <StyledListItem
          button
          isSelected={location.pathname === '/dashboardPage'}
          onClick={() => handleNavigation('dashboardPage')}
        >
          <Analytics />
          <TextTransition primary="Dashboard" isSidebarOpen={isSidebarOpen} />
        </StyledListItem>
        <StyledListItem
          button
          isSelected={location.pathname === '/uploadadmin'}
          onClick={() => handleNavigation('uploadadmin')}
        >
          <Upload />
          <TextTransition primary="Upload" isSidebarOpen={isSidebarOpen} />
        </StyledListItem>
        <StyledListItem
          button
          isSelected={location.pathname === '/report'}
          onClick={() => handleNavigation('report')}
        >
          <Report />
          <TextTransition primary="Report" isSidebarOpen={isSidebarOpen} />
        </StyledListItem>
      </List>
    </SidebarContainer>
  );
};

export default Sidebar;

// UserQueryPage.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import UserQueryForm from '../components/UserQuery';
import { styled } from '@mui/system';

const QueryPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(270deg, #91eac9, #86a8e7, #ff7e5f, #feb47b);
  background-size: 800% 800%;
  animation: gradientBackground 15s ease infinite;
`;

const ContentWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
`;

function UserQueryPage() {
  return (
    <QueryPageWrapper>
      <Navbar />
      <ContentWrapper>
        <UserQueryForm />
      </ContentWrapper>
      <Footer />
    </QueryPageWrapper>
  );
}

export default UserQueryPage;

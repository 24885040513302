import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/AdminNavbar';
import Footer from '../components/Footer';
import {
  Paper,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const MainContent = styled('div')`
  flex: 1;
  display: flex;
`;

const gradientAnimation = `
  @keyframes gradientBackground {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const ContentWrapper = styled('div')`
  ${gradientAnimation}
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 60px;
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eac9);
  background-size: 800% 800%;
  animation: gradientBackground 15s ease infinite;
  width: calc(100% - 240px);
  margin-top: 60px;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 10px 60px;
  }
`;

const UploadWrapper = styled(Paper)`
  background: white;
  margin: 0 auto;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  text-align: center;
  margin-top: 100px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 70px;
    width: 250px;
    margin-left: 100px;
  }
`;

const DropZone = styled(Box)`
  border: 2px dashed #ccc;
  border-radius: 12px;
  padding: 40px;
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropZoneText = styled(Typography)`
  font-size: 16px;
  color: #666;
`;

const FileInput = styled('input')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const DocumentCard = styled(Card)`
  background: #f9f9f9;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  margin-bottom: 16px;
  padding: 16px;
  border: ${({ isAdmin }) => (isAdmin ? '2px solid green' : 'none')};
`;

const DocumentActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
`;

const OpenChatButton = styled(Button)`
  && {
    color: white;
    background-color: #1976d2;
    margin-left: 15px;
    &:hover {
      background-color: #1565c0;
    }
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

const ActionButton = styled(IconButton)`
  && {
    color: #d32f2f;
    &:hover {
      color: #b71c1c;
    }
  }
`;

function UploadAdmin() {
  const [file, setFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  const models = [
    { id: 1, name: 'Model A' },
    { id: 2, name: 'Model B' },
    { id: 3, name: 'Model C' },
  ];

  const manufacturers = [
    { id: 1, name: 'Manufacturer X' },
    { id: 2, name: 'Manufacturer Y' },
    { id: 3, name: 'Manufacturer Z' },
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setStatusMessage('You must be logged in to view your documents.');
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_API}/documents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents', error);
        setStatusMessage('Failed to fetch documents. Please try again.');
      }
    };

    
    const fetchUserRole = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setStatusMessage('You must be logged in to view your role.');
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_API}/auth/role`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error fetching user role', error);
        setStatusMessage('Failed to fetch user role. Please try again.');
      }
    };

    fetchDocuments();
    fetchUserRole();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (validateFileType(selectedFile)) {
      setFile(selectedFile);
      setStatusMessage('');
    } else {
      setStatusMessage('Please select a PDF, DOCX, DOC, TXT, MP4, AVI, or MOV file.');
    }
  };

  const validateFileType = (file) => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
      'video/mp4',
      'video/avi',
      'video/quicktime',
    ];
    return allowedTypes.includes(file.type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setStatusMessage('Please select a file to upload.');
      return;
    }
    if (!selectedModel || !selectedManufacturer) {
      setStatusMessage('Please select a model and manufacturer.');
      return;
    }

    const formData = new FormData();
    formData.append('files', file, encodeURIComponent(file.name));
    formData.append('model', selectedModel);
    formData.append('manufacturer', selectedManufacturer);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setStatusMessage('You must be logged in to upload files.');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API}/documents/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setStatusMessage('Successfully uploaded');
      const documentId = response.data.responses[0].newDocument._id;
      navigate(`/chat/${documentId}`);
    } catch (error) {
      console.error('Error uploading file', error);
      if (error.response && error.response.status === 401) {
        setStatusMessage('Unauthorized. Please log in.');
        navigate('/login');
      } else {
        setStatusMessage('Failed to upload file. Please try again.');
      }
    }
  };

  const handleDocumentClick = (documentId) => {
    navigate(`/chat/${documentId}`);
  };

  const handleDeleteClick = (documentId) => {
    setSelectedDocId(documentId);
    setConfirmDelete(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedDocId) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setStatusMessage('You must be logged in to delete files.');
        return;
      }

      await axios.delete(`${process.env.REACT_APP_API}/documents/${selectedDocId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setDocuments(documents.filter(doc => doc._id !== selectedDocId));
      setStatusMessage('Document deleted successfully.');
    } catch (error) {
      console.error('Error deleting document', error);
      if (error.response && error.response.status === 401) {
        setStatusMessage('Unauthorized. Please log in.');
        navigate('/login');
      } else if (error.response && error.response.status === 404) {
        setStatusMessage('Document not found.');
      } else {
        setStatusMessage('Only Admin can delete Admin documents.');
      }
    }

    setConfirmDelete(false);
    setSelectedDocId(null);
  };

  const handleDeleteCancel = () => {
    setConfirmDelete(false);
    setSelectedDocId(null);
  };

  return (
    <PageWrapper>
      <Navbar />
      <MainContent>
        <Sidebar />
        <ContentWrapper>
          <UploadWrapper>
            <Typography variant="h4" gutterBottom>
              Upload and Attach Manual or Video
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Upload and attach manuals or videos to ask questions.
            </Typography>
            <form onSubmit={handleSubmit}>
              <DropZone>
                <DropZoneText>
                  {file ? file.name : 'Click to upload or drag and drop'}
                  <br />
                  <small>Maximum file size 100MB</small>
                </DropZoneText>
                <FileInput
                  type="file"
                  onChange={handleFileChange}
                  accept=".pdf,.docx,.doc,.txt,.mp4,.avi,.mov"
                />
              </DropZone>
              <Box mb={2} width="100%">
                <FormControl fullWidth>
                  <InputLabel>Model</InputLabel>
                  <Select
                    value={selectedModel}
                    onChange={(e) => setSelectedModel(e.target.value)}
                  >
                    {models.map((model) => (
                      <MenuItem key={model.id} value={model.name}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2} width="100%">
                <FormControl fullWidth>
                  <InputLabel>Manufacturer</InputLabel>
                  <Select
                    value={selectedManufacturer}
                    onChange={(e) => setSelectedManufacturer(e.target.value)}
                  >
                    {manufacturers.map((manufacturer) => (
                      <MenuItem key={manufacturer.id} value={manufacturer.name}>
                        {manufacturer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {statusMessage && (
                <Typography variant="body2" color="error" gutterBottom>
                  {statusMessage}
                </Typography>
              )}
              <Box mt={2} display="flex" justifyContent="center" gap={2}>
                <Button variant="contained" color="primary" type="submit">
                  Attach files
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/dashboardPage')}
                >
                  Cancel
                </Button>
              </Box>
            </form>
            <Grid container spacing={2}>
              {documents.map((doc) => (
                <Grid item xs={12} sm={6} md={4} key={doc._id}>
                  <DocumentCard isAdmin={doc.isAdminUploaded}>
                    <CardContent>
                      <InsertDriveFileIcon fontSize="large" color="action" />
                      <Typography variant="body1" gutterBottom>
                        {doc.filename}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Uploaded on: {new Date(doc.upload_time).toLocaleDateString()}
                      </Typography>
                    </CardContent>
                    <DocumentActions>
                      <OpenChatButton
                        size="small"
                        onClick={() => handleDocumentClick(doc._id)}
                      >
                        Open Chat
                      </OpenChatButton>
                      <ActionButton
                        size="small"
                        onClick={() => handleDeleteClick(doc._id)}
                      >
                        <DeleteIcon />
                      </ActionButton>
                    </DocumentActions>
                  </DocumentCard>
                </Grid>
              ))}
            </Grid>
          </UploadWrapper>
        </ContentWrapper>
      </MainContent>

      <Dialog open={confirmDelete} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </PageWrapper>
  );
}

export default UploadAdmin;

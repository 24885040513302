import React from 'react';
import Chat from '../components/Chat';
import styled from 'styled-components';

const ChatPageWrapper = styled('div')`
  display: flex;
  height: 100vh;
  background-color: #041B1E;
`;

const ChatPage = () => {
  return (
    <ChatPageWrapper>
      <Chat />
    </ChatPageWrapper>
  );
}

export default ChatPage;

import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Home } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

// Styled components
const NavbarContainer = styled(Box)(({ theme, isSidebarOpen }) => ({
  position: 'fixed',
  top: 0,
  left: isSidebarOpen ? 0 : 0, 
  right: 0,
  backgroundColor: 'black',
  color: 'white',
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1200,
  height: '45px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  transition: 'left 0.3s ease', 
  [theme.breakpoints.down('md')]: {
    left: 0, 
  },
}));

const StyledSignOutButton = styled(Button)({
  color: 'white',
  borderColor: 'white',
  '&:hover': {
    backgroundColor: '#1abc9c',
    borderColor: '#1abc9c',
  },
});

const Navbar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleHomeClick = () => {
    navigate('/dashboardPage'); 
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/dashboardPage':
        return 'Analytics Dashboard';
      case '/uploadadmin':
        return 'Upload Page';
      case '/report':
        return 'Report Page';
      default:
        return 'Dashboard';
    }
  };

  const pageTitle = getPageTitle(location.pathname);

  return (
    <NavbarContainer isSidebarOpen={isSidebarOpen}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleHomeClick}>
          <Home style={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
        {pageTitle}
      </Typography>
      <StyledSignOutButton variant="outlined" onClick={handleSignOut}>
        Sign Out
      </StyledSignOutButton>
    </NavbarContainer>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/AdminNavbar";
import Footer from "../components/Footer";
import { FaUsers, FaComments, FaFileAlt, FaClipboardList } from "react-icons/fa";
import axios from "axios";

const DashboardContainer = styled(Box)(({ theme, isSidebarOpen }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  paddingTop: "48px",
  marginLeft: isSidebarOpen ? 100 : 0,
  transition: "margin-left 0.3s",
  backgroundColor: theme?.palette?.background?.default || "#f0f0f0",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme?.spacing?.(2) || "16px",
  overflowY: "auto",
  [theme.breakpoints.up("md")]: {
    padding: theme?.spacing?.(3) || "24px",
  },
}));

const Card = styled(Paper)(({ theme }) => ({
  padding: "24px",
  margin: "16px",
  backgroundColor: "#ffffff",
  color: "#333333",
  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)",
  },
}));

const CardIcon = styled(IconButton)(({ theme }) => ({
  color: theme?.palette?.primary?.main || "#3f51b5",
  backgroundColor: "#f0f0f0",
  borderRadius: "50%",
  padding: "12px",
  marginBottom: "16px",
}));

const CardContent = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  backgroundColor: theme?.palette?.background?.paper || "#fafafa",
  color: theme?.palette?.text?.primary || "#333",
  borderBottom: "1px solid #ddd",
}));

const StatusLabel = styled(Typography)(({ status }) => ({
  fontSize: '15px',
  color: status === "solved" ? "green" : "orange",
}));

const RoleCell = ({ role }) => {
  const roleStyle = {
    color: role === 'admin' ? 'green' : 'inherit'
  };

  return <TableCell style={roleStyle}>{role}</TableCell>;
};

const DocumentVisibilityLabel = styled(Typography)(({ visibleToAll }) => ({
  fontSize: '15px',
  color: visibleToAll ? 'green' : 'orange',
}));

const DashboardPage = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [queries, setQueries] = useState([]);
  const [loadingQueries, setLoadingQueries] = useState(true);
  const [queryError, setQueryError] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [totalDocuments, setTotalDocs] = useState(0);
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [totalChats, setTotalChats] = useState(0);
  const [loadingChats, setLoadingChats] = useState(true);
  const [users, setUsers] = useState([]);
  const [loadingUsersList, setLoadingUsersList] = useState(true);
  const [usersError, setUsersError] = useState("");
  const [documentNames, setDocumentNames] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [documentsError, setDocumentsError] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/query/get`);
        setQueries(response.data);
      } catch (error) {
        setQueryError("An error occurred while fetching queries.");
      } finally {
        setLoadingQueries(false);
      }
    };

    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/auth/totalUsers`);
        console.log("Total Users Response: ", response.data);
        setTotalUsers(response.data.totalUsers);
      } catch (error) {
        console.error("An error occurred while fetching total users:", error.message);
      } finally {
        setLoadingUsers(false);
      }
    };

    const fetchTotalDocs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API}/documents/total`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("Total Docs Response: ", response.data);
        setTotalDocs(response.data.totalDocuments);
      } catch (error) {
        console.error("An error occurred while fetching total Docs:", error.response ? error.response.data : error.message);
      } finally {
        setLoadingDocs(false);
      }
    };

    const fetchTotalChats = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API}/chats/totalCount`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("Total Chats Response: ", response.data);
        setTotalChats(response.data.totalChats);
      } catch (error) {
        console.error("An error occurred while fetching total Chats:", error.response ? error.response.data : error.message);
      } finally {
        setLoadingChats(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/auth/users`);
        setUsers(response.data.users);
      } catch (error) {
        setUsersError("An error occurred while fetching users.");
      } finally {
        setLoadingUsersList(false);
      }
    };

    const fetchDocumentNames = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API}/documents/names`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDocumentNames(response.data);
        console.log(response.data);
      } catch (error) {
        setDocumentsError("An error occurred while fetching document names.");
      } finally {
        setLoadingDocuments(false);
      }
    };

    fetchTotalChats();
    fetchTotalDocs();
    fetchQueries();
    fetchTotalUsers();
    fetchUsers();
    fetchDocumentNames();
  }, []);

  const updateQueryStatus = async (id, status) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API}/query/updateStatus`, { id, status });
      setQueries((prevQueries) =>
        prevQueries.map((query) =>
          query._id === id ? { ...query, status } : query
        )
      );
    } catch (error) {
      console.error("Error updating query status:", error.message);
    }
  };

  const toggleDocumentVisibility = async (id, currentVisibility) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      await axios.patch(
        `${process.env.REACT_APP_API}/documents/visibility/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setDocumentNames((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc._id === id ? { ...doc, visibleToAll: !currentVisibility } : doc
        )
      );
    } catch (error) {
      console.error("Error toggling document visibility:", error.message);
    }
  };

  return (
    <DashboardContainer isSidebarOpen={isSidebarOpen}>
      <Sidebar selected={selectedTab} onSelect={setSelectedTab} onToggle={toggleSidebar} />
      <MainContent>
        <Navbar isSidebarOpen={isSidebarOpen} />
        <Grid container spacing={3}>
          {/* Overview Cards */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardIcon>
                <FaComments size={24} />
              </CardIcon>
              <CardContent>
                <Typography variant="h6">Total Chats</Typography>
                <Typography variant="h4">
                  {loadingChats ? <CircularProgress size={24} /> : totalChats}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardIcon>
                <FaUsers size={24} />
              </CardIcon>
              <CardContent>
                <Typography variant="h6">Total Users</Typography>
                <Typography variant="h4">
                  {loadingUsers ? <CircularProgress size={24} /> : totalUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardIcon>
                <FaFileAlt size={24} />
              </CardIcon>
              <CardContent>
                <Typography variant="h6">Total Documents</Typography>
                <Typography variant="h4">
                  {loadingDocs ? <CircularProgress size={24} /> : totalDocuments}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* User Queries Table */}
          <Grid item xs={12}>
            <Card>
              <CardIcon>
                <FaClipboardList size={24} />
              </CardIcon>
              <Typography variant="h6">User Queries</Typography>
              {loadingQueries ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                  <CircularProgress />
                </Box>
              ) : queryError ? (
                <Typography color="error">{queryError}</Typography>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Message</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {queries?.map((query) => (
                        <TableRow key={query._id}>
                          <TableCell>{query.name}</TableCell>
                          <TableCell>{query.email}</TableCell>
                          <TableCell>{query.message}</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={query.status === "solved"}
                                  onChange={() => updateQueryStatus(query._id, query.status === "pending" ? "solved" : "pending")}
                                  color="primary"
                                />
                              }
                              label={
                                <StatusLabel status={query.status}>
                                  {query.status === "pending" ? "Pending" : "Solved"}
                                </StatusLabel>
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Card>
          </Grid>

          {/* Uploaded Documents Table */}
          <Grid item xs={12}>
            <Card>
              <CardIcon>
                <FaFileAlt size={24} />
              </CardIcon>
              <Typography variant="h6">Uploaded Documents</Typography>
              {loadingDocuments ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                  <CircularProgress />
                </Box>
              ) : documentsError ? (
                <Typography color="error">{documentsError}</Typography>
              ) : documentNames.length === 0 ? (
                <Typography>No documents uploaded.</Typography>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Document Name</StyledTableCell>
                        <StyledTableCell>Uploaded Time</StyledTableCell>
                        <StyledTableCell>Visible to Users</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentNames.map((doc) => (
                        <TableRow key={doc._id}>
                          <TableCell>{doc.filename}</TableCell>
                          <TableCell>{doc.upload_time}</TableCell>
                          <TableCell>
                            {doc.uploaderRole === 'admin' && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={doc.visibleToAll}
                                    onChange={() => toggleDocumentVisibility(doc._id, doc.visibleToAll)}
                                    color="primary"
                                  />
                                }
                                label={
                                  <DocumentVisibilityLabel visibleToAll={doc.visibleToAll}>
                                    {doc.visibleToAll ? "Public" : "Private"}
                                  </DocumentVisibilityLabel>
                                }
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Card>
          </Grid>

          {/* Registered Users Table */}
          <Grid item xs={12}>
            <Card>
              <CardIcon>
                <FaUsers size={24} />
              </CardIcon>
              <Typography variant="h6">Registered Users</Typography>
              {loadingUsersList ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                  <CircularProgress />
                </Box>
              ) : usersError ? (
                <Typography color="error">{usersError}</Typography>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Username</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Role</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow key={user._id}>
                          <TableCell>{user.username}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <RoleCell role={user.role} />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Card>
          </Grid>
        </Grid>
      </MainContent>
      <Footer />
    </DashboardContainer>
  );
};

export default DashboardPage;

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Drawer, IconButton, ListItemText, TextField, Button, Toolbar,
  AppBar, Paper, List, ListItem, Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import {
  ChevronLeft, ChevronRight, Send, AddCircle, ArrowForward
} from '@mui/icons-material';
import axios from 'axios';
import { UserIcon, LightBulbIcon } from '@heroicons/react/24/outline'; 
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Define styles
const ChatWrapper = styled('div')`
  display: flex;
  flex: 1;
  height: 100vh;
  background-color: #2a2a2a;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ChatArea = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #2a2a2a;
  transition: margin-left 0.3s ease;
  margin-left: ${(props) => (props.isSidebarOpen ? '300px' : '0')};
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const ChatHeader = styled(AppBar)`
  background-color: #1e1e1e !important;
  z-index: 1201;
`;

const ChatMessages = styled(Paper)`
  flex: 1;
  padding: 20px;
  padding-bottom: 80px;
  overflow-y: auto;
  margin-top: 64px;
  background-color: #2a2a2a !important;
  box-shadow: none !important;
`;

const Message = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  ${(props) => (props.isUser ? 'flex-direction: row-reverse;' : 'flex-direction: row;')}
  margin-right: ${(props) => (props.isUser ? '0' : 'auto')};  // Align right for user messages
  margin-left: ${(props) => (props.isUser ? 'auto' : '0')};    // Align left for replies
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  ${(props) => (props.isUser ? 'margin-left: 10px;' : 'margin-right: 10px;')}
`;

const UserIconStyled = styled(UserIcon)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #0056b3;
`;

const AiIconStyled = styled(LightBulbIcon)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background-color: #28a745;
  color: #fff;
  border-radius: 50%;
  border: 2px solid #1c7430;
`;

const MessageContent = styled(Paper)`
  background-color: ${(props) => (props.isUser ? '#3a3a3a' : '#444')} !important;
  color: ${(props) => (props.isUser ? '#fff' : '#fff')} !important;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  margin-left: ${(props) => (props.isUser ? '0' : '10px')};  // Margin for replies
  margin-right: ${(props) => (props.isUser ? '10px' : '0')};  // Margin for user messages
  box-shadow: none !important;

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
  }
`;

const ChatInputArea = styled(Paper)`
  display: flex;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: calc(100% - ${(props) => (props.isSidebarOpen ? '320px' : '20px')});
  background-color: #1e1e1e !important;
  box-shadow: none !important;
  transition: width 0.3s ease;

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    padding-bottom: 20px;  // Ensure enough space for mobile input
  }
`;

const SidebarHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 10px;
  background-color: #1e1e1e;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #333;
`;

const ConversationList = styled(List)`
  flex: 1;
  overflow-y: auto !important;
`;

const ConversationItem = styled(ListItem)`
  padding: 10px !important;
  border-bottom: 1px solid #333 !important;
  color: ${(props) => (props.isSelected ? '#fff' : '#ccc')} !important;
  background-color: ${(props) => (props.isSelected ? '#444' : 'transparent')} !important;
  cursor: pointer !important;
  &:hover {
    background-color: ${(props) => (props.isSelected ? '#444' : '#333')} !important;
  }
`;

const ToggleButton = styled(IconButton)`
  position: fixed;
  left: ${(props) => (props.isOpen ? '300px' : '0')};
  top: 20px;
  background-color: #1e1e1e;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 1201;
  transition: left 0.3s ease;
`;

const ResponsiveTypography = styled(Typography)`
  transition: margin-left 0.3s ease;
  margin-left: ${(props) => (props.isSidebarOpen ? '250px' : '0')};
  width: calc(100% - ${(props) => (props.isSidebarOpen ? '320px' : '20px')});

  @media (max-width: 600px) {
    margin-left: 0;
    width: 100%;
  }
`;

const UploadButton = styled(Button)`
  color: white;
  margin-left: auto;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #1565c0;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    padding: 8px;
  }
`;

const Chat = () => {
  const { documentId, chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(chatId || null);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole();
      setUserRole(role);
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get(`${process.env.REACT_APP_API}/chats/document/${documentId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const chats = response.data.chats;
        setConversations(chats);
        setFilteredConversations(chats);

        if (chatId) {
          setCurrentConversationId(chatId);
          fetchMessages(chatId);
        } else if (chats.length > 0) {
          setCurrentConversationId(chats[0]._id);
          fetchMessages(chats[0]._id);
        } else {
          await addNewChat();
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
        setError('Failed to fetch chats. Please try again later.');
      }
    };

    fetchChats();
  }, [documentId, chatId]);

  useEffect(() => {
    if (currentConversationId) {
      fetchMessages(currentConversationId);
    }
  }, [currentConversationId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchMessages = async (chatId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${process.env.REACT_APP_API}/chats/messages/${chatId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const { messages, replies } = response.data;
      const messagesMap = messages.reduce((acc, message) => {
        acc[message.message_id] = { ...message, isUser: true, replies: [] };
        return acc;
      }, {});

      replies.forEach(reply => {
        if (messagesMap[reply.message_id]) {
          messagesMap[reply.message_id].replies.push({ ...reply, isUser: false });
        }
      });
  
      const messagesWithReplies = Object.values(messagesMap);
      setMessages(messagesWithReplies);
    } catch (error) {
      console.error('Error fetching messages:', error.message);
      setError(`Failed to load messages: ${error.message}`);
    }
  };
  
  const handleSendMessage = async () => {
    if (messageText.trim() !== '') {
      const userMessage = messageText;
      setMessageText('');
      const newMessageId = Date.now();
      const newMessages = [...messages, { id: newMessageId, text: userMessage, isUser: true }];
      setMessages(newMessages);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get(`${process.env.REACT_APP_API}/chats/query`, {
          params: { query: userMessage, documentId, chatId: currentConversationId },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const aiContent = response.data.answer;
        const updatedMessages = [...newMessages, { id: Date.now(), text: aiContent, isUser: false, isMarkdown: true }];
        setMessages(updatedMessages);
        setError(null);
      } catch (error) {
        console.error('Error sending message:', error);
        setError('Failed to send message. Please try again later.');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const addNewChat = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}/chats/create`,
        { documentId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const newChat = response.data.chat;
      console.log('New chat created:', newChat);
      const newConversations = [...conversations, newChat];
      setConversations(newConversations);
      setFilteredConversations(newConversations);
      setCurrentConversationId(newChat._id);
      setMessages([]);
      fetchMessages(newChat._id);
    } catch (error) {
      console.error('Error creating new chat:', error);
      setError('Failed to create new chat. Please try again later.');
    }
  };

  const selectConversation = (conversation) => {
    setCurrentConversationId(conversation._id);
    fetchMessages(conversation._id);
  };

  const handleUploadPageNavigation = () => {
    if (userRole === 'admin') {
      navigate('/uploadadmin');
    } else {
      navigate('/upload');
    }
  };

  return (
    <ChatWrapper>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSidebarOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '200px',
              sm: '300px',
            },
            backgroundColor: '#1e1e1e',
            color: '#fff',
          },
        }}
      >
        <IconButton onClick={toggleSidebar}>
          <ChevronLeft style={{ color: '#fff' }} />
        </IconButton>
        <SidebarHeader>
          Conversations
          <IconButton onClick={addNewChat}>
            <AddCircle style={{ color: '#fff' }} />
          </IconButton>
        </SidebarHeader>
        <ConversationList>
          {filteredConversations.map((conversation, index) => (
            <ConversationItem
              button
              key={conversation._id}
              onClick={() => selectConversation(conversation)}
              isSelected={currentConversationId === conversation._id}
            >
              <ListItemText primary={`Chat ${index + 1}`} />
            </ConversationItem>
          ))}
        </ConversationList>
      </Drawer>
      <ToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
        {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </ToggleButton>
      <ChatArea isSidebarOpen={isSidebarOpen}>
        <ChatHeader position="fixed">
          <Toolbar>
            <IconButton color="inherit" onClick={toggleSidebar} edge="start">
              {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
            <ResponsiveTypography variant="h6" noWrap isSidebarOpen={isSidebarOpen}>
              Ask questions about the Manual
            </ResponsiveTypography>
            <UploadButton onClick={handleUploadPageNavigation}>
              <ArrowForward style={{ marginRight: '8px' }} />
              Back
            </UploadButton>
          </Toolbar>
        </ChatHeader>
        <ChatMessages>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          {Array.isArray(messages) && messages.length > 0 ? (
            messages.map((message) => (
              <div key={message._id}>
                <Message isUser={message.isUser}>
                  <IconWrapper isUser={message.isUser}>
                    {message.isUser ? <UserIconStyled /> : <AiIconStyled />}
                  </IconWrapper>
                  <MessageContent isUser={message.isUser}>
                    {message.isMarkdown ? (
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.text}</ReactMarkdown>
                    ) : (
                      message.text
                    )}
                  </MessageContent>
                </Message>
                {message.replies && message.replies.length > 0 && (
                  message.replies.map((reply) => (
                    <Message key={reply._id} isUser={reply.isUser}>
                      <IconWrapper isUser={reply.isUser}>
                        {reply.isUser ? <UserIconStyled /> : <AiIconStyled />}
                      </IconWrapper>
                      <MessageContent isUser={reply.isUser}>
                        {reply.isMarkdown ? (
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>{reply.text}</ReactMarkdown>
                        ) : (
                          reply.text
                        )}
                      </MessageContent>
                    </Message>
                  ))
                )}
              </div>
            ))
          ) : (
            <div>No messages to display.</div>
          )}
          <div ref={messagesEndRef} />
        </ChatMessages>
        <ChatInputArea isSidebarOpen={isSidebarOpen}>
          <TextField
            placeholder="Ask a Question"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyPress}
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                backgroundColor: '#333',
                color: '#fff',
                borderRadius: '4px',
              },
            }}
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: '#3a3a3a',
              color: '#fff',
              marginLeft: '10px',
              minWidth: '50px',
              height: '50px',
            }}
            onClick={handleSendMessage}
          >
            <Send />
          </Button>
        </ChatInputArea>
      </ChatArea>
    </ChatWrapper>
  );
};

const getUserRole = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }
    const response = await axios.get(`${process.env.REACT_APP_API}/auth/role`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data.role;
  } catch (error) {
    console.error('Error fetching user role:', error.message);
    return null;
  }
};

export default Chat;

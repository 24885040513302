import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';

const gradientAnimation = `
  @keyframes gradientBackground {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const PricingPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure full width */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  ${gradientAnimation}
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eac9);
  background-size: 800% 800%;
  animation: gradientBackground 15s ease infinite;
`;

const ContentWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 20px 40px; /* Adjust padding to account for sticky navbar */
  text-align: center;
  width: 100%; /* Ensure full width */
  margin: 0; /* Remove default margin */
  @media (max-width: 600px) {
    padding: 800px 0px 40px; /* Adjust padding for mobile screens */
  }
`;

const CardsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%; /* Ensure full width */
  padding: 0 20px; /* Add some padding to the sides */
`;

const Card = styled(Paper)`
  background-color: #1a1a1a;
  color: #ffffff;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px; /* Add some margin at the bottom for spacing */
`;

const CardTitle = styled(Typography)`
  font-size: 24px;
  margin-bottom: 10px;
`;

const CardPrice = styled(Typography)`
  font-size: 18px;
  margin: 10px 0;
`;

const CardOffer = styled(Typography)`
  font-size: 16px;
  color: #feb47b; /* Change to match gradient colors */
  margin-bottom: 20px;
`;

const CardFeatureList = styled('ul')`
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;
`;

const CardFeature = styled(Typography)`
  font-size: 16px;
  margin-bottom: 10px;
`;

const CardButton = styled(Button)`
  background: #feb47b;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(270deg, #91eac9, #86a8e7);
    transform: scale(1.05);
  }
`;

function PricingPage() {
  return (
    <PricingPageWrapper>
      <Navbar />
      <ContentWrapper>
        <Box mb={4}>
          <Typography variant="h1" sx={{ fontSize: '36px', marginBottom: '10px' }}>
            Pick your Premium
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: '18px', color: 'black' }}>
            Ask questions without limits about your manuals.
          </Typography>
        </Box>
        <CardsContainer>
          <Card>
            <CardTitle>Individual</CardTitle>
            <CardPrice>10.99 EUR/month</CardPrice>
            <CardOffer>3 months free</CardOffer>
            <CardFeatureList>
              <CardFeature>Ad-free music listening</CardFeature>
              <CardFeature>Play anywhere - even offline</CardFeature>
              <CardFeature>On-demand playback</CardFeature>
            </CardFeatureList>
            <CardButton>Get Started</CardButton>
          </Card>
          <Card>
            <CardTitle>Duo</CardTitle>
            <CardPrice>14.99 EUR/month</CardPrice>
            <CardOffer>1 month free</CardOffer>
            <CardFeatureList>
              <CardFeature>2 Premium accounts for a person</CardFeature>
              <CardFeature>Ad-free music listening, play offline</CardFeature>
              <CardFeature>On-demand playback</CardFeature>
            </CardFeatureList>
            <CardButton>Get Started</CardButton>
          </Card>
          <Card>
            <CardTitle>Team Collaboration</CardTitle>
            <CardPrice>18.99 EUR/month</CardPrice>
            <CardOffer>1 month free</CardOffer>
            <CardFeatureList>
              <CardFeature>6 Premium accounts for team members</CardFeature>
              <CardFeature>Block explicit music</CardFeature>
              <CardFeature>Ad-free music listening, play offline</CardFeature>
            </CardFeatureList>
            <CardButton>Get Started</CardButton>
          </Card>
        </CardsContainer>
      </ContentWrapper>
      <Footer />
    </PricingPageWrapper>
  );
}

export default PricingPage;

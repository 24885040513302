import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Signup from '../components/Auth/SignUp';

const PageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eac9);
  background-size: 800% 800%;
  animation: gradientBackground 15s ease infinite;
  text-align: center;

  @keyframes gradientBackground {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const LeftSection = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 60px;
  text-align: left;
  background: linear-gradient(to top right, #1f3c88, #16222a);

  @media (max-width: 600px) {
    padding: 30px;
    text-align: center;
  }
`;

const RightSection = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 60px;

  @media (max-width: 600px) {
    padding: 30px;
  }
`;

const SplitScreen = styled(Box)`
  display: flex;
  width: 100%;
  max-width: 1200px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 10px; /* Add some margin to avoid touching screen edges */
  }
`;

const SignupPage = () => {
  return (
    <PageWrapper>
      <SplitScreen>
        <LeftSection>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Welcome to Our Community
          </Typography>
          <Typography variant="body1">
            Effortlessly upload your manuals and get instant answers to all your questions.
          </Typography>
        </LeftSection>
        <RightSection>
          <Container>
            <Signup />
          </Container>
        </RightSection>
      </SplitScreen>
    </PageWrapper>
  );
};

export default SignupPage;

import React from 'react';
import styled from 'styled-components';
import PricingPage from '../components/PricingCard'; // Ensure the path is correct and the file name matches

const CardPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
`;

function PricingCard() {
  return (
    <CardPageWrapper>
      <PricingPage />
    </CardPageWrapper>
  );
}

export default PricingCard;

import React, { useState } from 'react';
import { Box, TextField, Button, Link, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SignupWrapper = styled(Paper)`
  padding: 40px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  box-shadow: none;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
`;

const SignupButton = styled(Button)`
  background: linear-gradient(to right, #2b5876, #4e4376);
  color: white;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;

  &:hover {
    background: linear-gradient(to left, #2b5876, #4e4376);
  }

  @media (max-width: 600px) {
    padding: 8px;
    margin-top: 10px;
  }
`;

const LoginLink = styled(Link)`
  margin-top: 20px;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { username, email, password } = formData;
    let isValid = true;
    const newErrors = {};

    if (!username) {
      newErrors.username = 'Username is required.';
      isValid = false;
    }

    if (!email) {
      newErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid.';
      isValid = false;
    }

    if (!password) {
      newErrors.password = 'Password is required.';
      isValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/auth/register`, formData);
      setMessage('Registered Successfully.');
      navigate('/login');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setMessage('Username or email already exists.');
      } else {
        setMessage('Failed to register user.');
      }
    }
  };

  return (
    <SignupWrapper>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        User Signup
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <IconWrapper>
          <AccountCircleIcon sx={{ color: '#2b5876', marginRight: '10px' }} />
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
        </IconWrapper>
        <IconWrapper>
          <EmailIcon sx={{ color: '#2b5876', marginRight: '10px' }} />
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </IconWrapper>
        <IconWrapper>
          <LockIcon sx={{ color: '#2b5876', marginRight: '10px' }} />
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />
        </IconWrapper>
        <SignupButton fullWidth variant="contained" type="submit">
          SIGN UP
        </SignupButton>
        <LoginLink variant="body2" onClick={() => navigate('/login')}>
          Already have an account? Log In
        </LoginLink>
      </Box>
      {message && <Typography variant="body2" color="error">{message}</Typography>}
    </SignupWrapper>
  );
};

export default Signup;

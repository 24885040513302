import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/loginpage';
import SignupPage from './pages/signuppage';
import UploadPage from './pages/uploadpage';
import ChatPage from './pages/chatpage';
import PricingPage from './pages/pricingpage';
import HomePage from './pages/Homepage';
import QueryPage from './pages/userQueryPage';
import AdminLoginPage from './pages/adminLoginPage';
import AdminDashboard from './pages/dashboardPage';
import ReportPage from './pages/reportpage';
import UploadPageAdmin from './pages/uploadpageAdmin';

const PrivateRoute = ({ element, adminOnly, ...rest }) => {
  const token = localStorage.getItem('token');  // Check for a token
  const userType = localStorage.getItem('userType');  // Check for user type (e.g., 'admin' or 'user')

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && userType !== 'admin') {
    return <Navigate to="/admin-login" />;  // Redirect non-admin users to a default page
  }

  return element;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/upload" element={<PrivateRoute element={<UploadPage />} />} />
          <Route path="/chat/:documentId" element={<PrivateRoute element={<ChatPage />} />} />
          <Route path="/pricing" element={<PrivateRoute element={<PricingPage />} />} />
          <Route path="/home" element={<PrivateRoute element={<HomePage />} />} />
          <Route path="/queryPage" element={<PrivateRoute element={<QueryPage />} />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/admin-login" element={<AdminLoginPage />} />
          <Route path="/dashboardPage" element={<PrivateRoute element={<AdminDashboard />} adminOnly />} />
          <Route path="/report" element={<PrivateRoute element={<ReportPage />} adminOnly />} />
          <Route path="/uploadAdmin" element={<PrivateRoute element={<UploadPageAdmin />} adminOnly />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import Upload from '../components/UploadAdmin';
import styled from 'styled-components';

const UploadPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
`;

function UploadPage() {
  return (
    <UploadPageWrapper>
      <Upload />
    </UploadPageWrapper>
  );
}

export default UploadPage;
